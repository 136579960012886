import { useState, useEffect } from "react";
import { Container } from "./styles";
import VinayakSingh from "../../assets/VinayakSingh.png";
import wordpress from "../../assets/wordpress.svg";
import shopify from "../../assets/telegram.svg";
import Li from "../../assets/linkedin.svg";
import Whatsapp from "../../assets/whatsapp.svg";
import Insta from "../../assets/instagram.svg";
import FB2 from "../../assets/fb2.svg";
import FB from "../../assets/fb.svg";
import ScrollAnimation from "react-animate-on-scroll";
import Yt from "../../assets/yt.svg";
import logo from "../../assets/logo.jpg";
import dp from "../../assets/dp.jpg";

export function About() {
  const [currentImage, setCurrentImage] = useState<string>(logo); // Initial image is logo
  const [fade, setFade] = useState<boolean>(false); // State to handle fade transition

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Start fade out

      setTimeout(() => {
        // Switch image after fade out completes
        setCurrentImage((prevImage) => (prevImage === logo ? dp : logo));
        setFade(false); // Fade back in
      }, 500); // 500ms fade-out time
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About Us</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
            I'm from Funkey Creators, a social media marketing company
            specializing in brand deals for YouTubers. I'd love to connect and
            explore potential collaborations.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={0.2 * 1000}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <p>
            Our expertise lies in fostering relationships that drive growth and
            innovation, connecting brands with the perfect influencers to
            amplify their message and reach. At Funkey Creators, we are
            dedicated to delivering exceptional results through strategic
            marketing solutions tailored to meet your unique needs.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
            Join us in transforming ideas into powerful campaigns that leave a
            lasting impact.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Here are my main skills:</h3>
        </ScrollAnimation>
        <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
              <img src={Li} alt="LinkedIn" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
              <img src={FB} alt="Facebook" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
              <img src={Insta} alt="Instagram" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
              <img src={Yt} alt="YouTube" />
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 1000}>
          {/* Apply the fade effect */}
          <img
            src={currentImage}
            alt="Logo or DP"
            className={fade ? "fade" : ""} // Apply fade class when fade is true
          />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
